import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import { Link } from "gatsby"

const AboutUs = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Eroare 404"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Eroare 404"
      />
      <section id="not-found">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
            </div>
            <div className="col-md-6 info-text">
              <h2 className="mb-5 h3-title">Pagina nu a fost gasită</h2>
              <p>Se pare ca pagina pe care incerci sa o accesezi nu mai există sau a fost mutată.</p>
              <p>Intoarce-te acasa sau incearca sa ne contactezi pe pagina de <Link to="/contact/">contact</Link>.</p>
              <Link class="view-more-button" to="/">
                Acasă
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
}

export default AboutUs;
